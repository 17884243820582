.tabla-sumatoria .ant-form-item {
  margin-bottom: 0 !important;
}
.tabla-sumatoria td,
.tabla-sumatoria th {
  padding: 2px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
textarea {
  width: 100%;
}
.ant-layout,
.ant-layout-footer,
body {
  background-color: #ffff !important;
}
.ant-layout-header {
  background: #1651aa;
}
.ant-layout-header .ant-breadcrumb span {
  color: rgba(255, 255, 255, 0.7);
}
.ant-layout-header .ant-breadcrumb a {
  color: rgba(255, 255, 255, 0.45);
}
.ant-layout-header .ant-breadcrumb > span:last-child {
  color: rgba(255, 255, 255, 0.85);
}
.ant-layout-header .ant-typography {
  color: #ffff;
}
.ant-layout,
.ant-layout-footer {
  background: #f0f2f563;
  background-color: #f0f2f563;
}
.ant-divider-inner-text {
  font-size: large;
}

.rectangle {
  width: 32px;
  height: 16px;
}

.circle,
.cube {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin: 0 auto;
}
.ant-tree-title .cube {
  width: 8px;
  height: 8px;
}
.ant-tabs-tab-disabled {
  display: none;
}
.circle {
  border-radius: 50%;
}
.estado-equipo-1 {
  background: #28b463;
}
.estado-equipo-2 {
  background: #f1c40f;
}
.estado-equipo-3 {
  background: #e74c3c;
}
.estado-equipo-4 {
  background: #7e7e7e;
}
.text-estado-1 {
  font-weight: bold;
  color: #28b463;
}
.text-estado-2 {
  font-weight: bold;
  color: #f1c40f;
}
.text-estado-3 {
  font-weight: bold;
  color: #e74c3c;
}
.text-estado-4 {
  font-weight: bold;
  color: #7e7e7e;
}

.block-module {
  position: relative;
  text-align: center;
  background-color: #f8f9f9;
  padding: 8px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
}

.module-icon {
  width: 32px;
}
.block-module.disabled:hover {
  cursor: not-allowed;
}
.block-module.disabled .module-icon {
  filter: grayscale(1);
}
.block-module.disabled .ant-typography {
  color: silver;
}

.row-flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 -16px;
}
.col-flex {
  flex: auto;
  margin: 0 16px;
}

.day-cell {
  background: rgb(168, 155, 155);
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.scheduler_default_event {
  height: 24px !important;
  border-radius: 24px;
  margin-top: 6px;
}

.scheduler_default_event_inner {
  padding-left: 24px;
  text-align: center;
}

.table-report td,
.table-report th {
  padding: 8px;
}
tr.order-material-pending:hover > td {
  background: #f1c40f;
  opacity: 0.8;
}
tr.order-material-pending td {
  background-color: #f1c40f;
}
tr.order-material-inprogress td {
  background-color: #99a3a4;
}
tr.order-material-done td {
  background-color: #28b463;
}

tr.td-highlight td:nth-of-type(7) ~ td {
  background-color: #99a3a4;
}
tr.td-highlight-success td:nth-of-type(7) ~ td {
  background-color: #28b463;
  color: #fff;
  font-weight: bold;
}
tr.td-highlight-success:hover td {
  color: #000 !important;
  font-weight: bold;
}

.gallery {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
}
.gallery li {
  display: -webkit-flex;
  display: flex;
  flex: 1;
  margin: 4px;
}
.gallery li img {
  border-radius: 4px;
}

.report-activitys {
  gap: 4px;
  width: 100%;
  flex-wrap: wrap;
  flex-flow: row wrap;
  display: flex;
}
.report-activity {
  border: 1px solid #808b96;
  padding: 16px;
  border-radius: 4;
  box-sizing: border-box;
}

.table {
  width: 100%;
  border-collapse: collapse;
}
.table td {
  padding: 4px;
  border: 1px solid #000;
}
.sub-activity {
  display: block;
  position: relative;
  margin-top: -24px;
  border-color: #1890ff;
}
.sub-activity::after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: -3px;
  width: 11px;
  height: 17px;
  background-color: #ffffff;
  border-top: 3px solid #1890ff;
}
.sub-activity .add-activity span:not(.anticon) {
  display: none;
}
.bg-dot {
  background: linear-gradient(90deg, #ffff 21px, transparent 1%) center,
    linear-gradient(#ffff 21px, transparent 1%) center, silver !important;
  background-size: 22px 22px !important;
}

span:first-letter,
p:first-letter,
.ant-typography:first-letter,
.ant-table-cell:first-letter {
  text-transform: capitalize;
}
.tachado,
.tachado span {
  text-decoration: line-through;
}

.tabla-cotizacion {
  width: 100%;
  border-collapse: collapse;
}
.tabla-cotizacion th {
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  padding: 6px;
}
.tabla-cotizacion tr td {
  padding: 6px;
  border: 1px solid #d9d9d9;
  text-align: right;
}
.tabla-cotizacion td .ant-input,
.tabla-cotizacion td .ant-input-number,
.tabla-cotizacion td .ant-select-selector {
  width: 100%;
}
.tabla-cotizacion tfoot td {
  border: none;
}
.tabla-cotizacion tfoot tr td:last-child {
  background-color: silver;
}
.reporte .ant-statistic-content-value-int,
.reporte .ant-statistic-content-prefix {
  font-size: 14px;
}
.report-table {
  margin-bottom: 16;
  position: relative;
  z-index: 99;
  border-collapse: collapse;
}
.report-table.items tbody td {
  border: 1px solid silver;
  padding: 0px 8px;
}
.report-table th {
  font-size: 14px;
}
.report-table td {
  font-size: 12px;
}
.no-aprobado {
  background-color: #e74c3c;
}
.aprobado {
  background-color: #28b463;
}

.ant-table-tbody > tr.ant-table-row-selected > td.no-aprobado {
  background-color: #e74d3cc4;
}
.ant-table-tbody > tr.ant-table-row-selected > td.aprobado {
  background-color: #28b463c4;
}

.ant-table-tbody > tr:hover > td.no-aprobado {
  background-color: #e74d3cc4;
}
.ant-table-tbody > tr:hover > td.aprobado {
  background-color: #28b463c4;
}
.td-money * {
  text-align: right;
  font-size: 14px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.table-horas-extras {
  border-collapse: collapse;
}
.table-horas-extras td,
.table-horas-extras th {
  border: 1px solid #7f8c8d;
}
.spendPlan {
  border-collapse: collapse;
}
.spendPlan th {
  background-color: #1651aa;
  color: #ffff;
  font-weight: bold;
  font-size: 18px;
}
.spendPlan th,
.spendPlan td {
  padding: 8px;
  border: 1px solid #000;
}
.spendPlan td {
  text-align: right;
}

.ant-btn .anticon ,.ant-btn .anticon svg{
  pointer-events: none;
}
/**
 * Print Stylesheet fuer Deinewebsite.de
* @version         1.0
* @lastmodified    16.06.2016
*/

@media print {
  body,
  html {
    margin: 0;
    padding: 0;
  }
  .report-left {
    width: 30%;
    height: 200vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f8f9f9;
  }
  .ant-typography {
    orphans: 4;
    widows: 5;
    text-align: justify;
  }
  h1,
  h2,
  h3 {
    break-after: avoid-page;
  }
  img,
  pre {
    break-inside: avoid-page;
  }

  @page {
    margin: 0mm;
  }
}
